const Select: React.FC<{ label: string, name:string, options: Array<String> }> = (props) => {
    const { label, name, options } = props;
    return(
        <>
            <select className="border-primary form-select py-2 mb-3" name={ name } aria-label=".form-select-lg example" defaultValue={ label }>
                <option>{ label }</option>
                {options.map( (option, i) => (
                    <option key={ i } value="1">{ option }</option>
                ) )}
            </select>
        </>
    );
}

export default Select;