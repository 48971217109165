const StepBox: React.FC<{step: number, title: string, hasButton: boolean, buttonText: string, text: string}> = (props) => {
    const {step, title, hasButton, buttonText, text} = props
    return(
        <>
            <div className="step-box">
                <h1 className="title title__pop--huge">{step}</h1>
                <div className={"content--" + step }>
                    <h5 className="title fw-bold" dangerouslySetInnerHTML={{ __html: title }} />
                    <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
                    {(hasButton)&&(
                        <a href="!#" className="px-4 btn btn-primary text-white rounded-pill">{ buttonText }</a>
                    )}
                </div>
            </div>
        </>
    );
}

export {StepBox};