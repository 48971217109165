
import {Link} from 'react-router-dom'
import patternLeft from '../images/patterns/pattern-left.png';
import patternRight from '../images/patterns/pattern-right.png';

const ErrorPage:React.FC<{ error: string, message: string }> = (props) => {
    const { error, message } = props
    return(
        <>
            <div className="error-page position-relative">
                <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-100px', left: '-90px' }}/>
                <div className="container error error__container">
                    <div className="w-100 mb-4">
                        <h1 className="title title__pop mb-3">{ error }</h1>
                        <h2 className="title fs-2 fw-normal text-center">{ message }</h2>
                        <Link to="/" className="mt-4 btn btn-primary">
                            Regresar
                        </Link>
                    </div>
                </div>
                <img src={ patternRight } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ bottom: '-250px', right: '-90px' }}/>
            </div>
        </>
    );
}

export {ErrorPage}