const OptionCard: React.FC<{ header: string, title: string, subtitle: string, text: string, buttonText: string }> = (props) => {
    const { header, title, subtitle, text, buttonText } = props;
    return (
        <>
            <div className="px-0 card border-0 col-lg-3 mx-2 mb-4 tipsters__card--option">
                <div className="card-header bg-primary">
                    <p className="mb-0 small" dangerouslySetInnerHTML={{ __html: header }} />
                </div>
                <div className="card-title p-3">
                    <div className="row">
                        <h1 className="title title__pop">{title}</h1>
                        <h2 className="title fs-6 fw-bold text-primary text-center">{subtitle}</h2>
                    </div>
                </div>
                <div className="card-body">
                    <p className="card-text text-center" dangerouslySetInnerHTML={{ __html: text }} />
                    <div className="d-flex justify-content-center">
                        <a href="!#" className="btn btn-dark rounded-pill py-2 px-4">{buttonText}</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export {OptionCard};