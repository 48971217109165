import patternLeft from '../images/patterns/pattern-left.png';
import patternRight from '../images/patterns/pattern-right.png';
import background from '../images/contacto/background-page.png';

import {ContactForm} from '../components/contact-form';
import { Download } from '../modules/contacto/Download';

const Contacto = () => {
    return(
        <>
            <div className="contacto-page position-relative" style={{ backgroundImage: `url(${ background })` }}>
                <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '0px', left: '-90px' }}/>
                <div className="first-content container z-2">
                    <div className="w-100 mb-4 row">
                        <h1 className="title title__pop z-1 mb-3">Contacto</h1>
                    </div>
                    <div className="row py-5 align-items-center justify-content-center flex-column-reverse flex-lg-row">
                        <div className="px-3 col-lg-6">
                            <ContactForm />
                        </div>
                        <div className="px-3 col-lg-3 d-flex flex-column align-items-start justify-content-center">
                            <h3 className="fw-bold contact-message-title">Resolvemos tus dudas</h3>
                            <p>Rellena el siguiente formulario y nos pondremos en contacto lo más rápido posible.</p>
                        </div>
                    </div>
                </div>
                <Download cssClass='bg-primary-light under-contact-row' />
                <img src={ patternRight } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ bottom: '-300px', right: '-90px' }}/>
            </div>
        </>
    );
}

export {Contacto};