import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line,  } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  onResize: () => {
    const chart = Object.values(ChartJS.instances).filter((c) => c.canvas ).pop()
    const parentElement = document.querySelector('#chart-parent')
    if (parentElement)
      chart?.resize(parentElement?.clientWidth - 10, 300);
    chart?.update()
  },
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      tension: 0.4,
      label: 'Profit',
      borderColor: '#E73758',
      backgroundColor: 'rgba(231, 55, 88, 0.35)',
      data: labels.map(() => Math.floor(Math.random() * (100 - 0 + 1) + 0)),
    },
    {
      fill: true,
      tension: 0.4,
      label: 'Yield',
      borderColor: 'rgba(13, 202, 240, 1)',
      backgroundColor: 'rgba(13, 202, 240, 0.6)',
      data: labels.map(() => Math.floor(Math.random() * (100 - 0 + 1) + 0)),
    },
    {
      fill: true,
      tension: 0.4,
      label: 'Yield FS',
      borderColor: '#198754',
      backgroundColor: 'rgba(25, 135, 84, 0.3)',
      data: labels.map(() => Math.floor(Math.random() * (100 - 0 + 1) + 0)),
    }
  ],
};

const Chart = () => <Line options={options} data={data} />;

export { Chart };
