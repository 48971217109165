import { StepBox } from './step-box/step-box';
import { StepList } from 'customTypes';

const Steps: React.FC<{stepList: StepList}> = (props) =>{
    const { stepList } = props
    return(
        <>
            {stepList.map((step, i) => (
                (i%2 === 0) ? (
                    <div key={i} className="row mb-3 justify-content-center">
                        <div className="col-md-7"></div>
                        <div className="col-md-5 d-flex justify-content-start">
                            <StepBox
                                step={step.step}
                                title={step.title}
                                hasButton={step.hasButton}
                                buttonText={step.buttonText}
                                text={step.text} />
                        </div>
                    </div>
                ):(
                    <div className="row mb-3 justify-content-center">
                        <div className="col-md-5 d-flex justify-content-end">
                        <StepBox
                            step={step.step}
                            title={step.title}
                            hasButton={step.hasButton}
                            buttonText={step.buttonText}
                            text={step.text} />
                        </div>
                        <div className="col-md-7"></div>
                    </div>
                )
            ))}
        </>
    );
}

export {Steps};