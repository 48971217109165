const TipsterFilter = () => {
    return(
        <>
            <div className="mt-5 d-flex justify-content-center">
                <a href="!#" className="z-2 px-4 mx-2 px-lg-5 rounded-pill btn btn-outline-primary is-active">Top Tipster</a>
                <a href="!#" className="z-2 px-4 mx-2 px-lg-5 rounded-pill btn btn-outline-primary">Mejores del mes</a>
                <a href="!#" className="z-2 px-4 mx-2 px-lg-5 rounded-pill btn btn-outline-primary">En Racha</a>
            </div>
        </>
    )
}

export {TipsterFilter};