//Modules
import Slider from '../components/slider/Splide';
import { ServicesLayout } from '../modules/service';
import { TipstersLayout } from '../modules/tipsters';
import Confidence from '../modules/confidence/Confidence';
import { FeedbackLayout } from '../modules/feedback';
//Images
import patternLeft from '../images/patterns/pattern-left.png';
import searchLogo from '../images/home/service/search.png';
import mailLogo from '../images/home/service/mail.png';
import cupLogo from '../images/home/service/cup.png';

const services = [
  { id: 1, name: 'Busca', text: 'Elige los mejores <span>tipster</span> que se adapten a tu perfil de inversión.', image: searchLogo },
  { id: 2, name: 'Recibe los pronósticos', text: 'De forma <span>inmediata</span> a través de nuestra app.', image: mailLogo },
  { id: 3, name: 'Gana', text: 'Rentabiliza tus <span>inversiones</span> con total transparencia.', image: cupLogo },
]

const Home = () => {
  return (
    <div className="home-page">
        <Slider />
        <div className="mt-5 home-content container-fluid position-relative">
          <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute left-0" style={{ top: '-100px', left: '-30px' }}/>
          <ServicesLayout {...services} />
          <TipstersLayout pattern={ true } />
          <Confidence />
          <FeedbackLayout />
        </div>
    </div>
  );
}

export {Home};