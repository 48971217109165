import gplay from '../../images/slider/google-play.png'
import applestore from '../../images/slider/apple-store.png'

type BannerProps = {
    image: string;
    height: number;
    text?: string;
    appBadges?: boolean;
    cssOptions?: Object;
    cssBadgesOptions?: Object;
}

const BBreadcrumb: React.FC<BannerProps> = ({ image, height, text, appBadges, cssOptions, cssBadgesOptions }) => {
    return(
        <>
            <div className="w-100 d-flex position-relative" 
                style={
                    {
                        height: `${ height }px`,
                        backgroundImage: `url('${image}')`,
                        ...cssOptions
                    }
                }>
                <div className="container d-flex align-items-center justify-content-start">
                    { !text ? (
                            <div className="row">
                                <h2 className="text-white display-4"><span className="fw-bold">¿Ya eres tipster?</span><br/> Trabaja con nosotros</h2>
                            </div>
                        ) : (
                            <div className="row">
                                <h2 className="text-white display-4" dangerouslySetInnerHTML={{ __html: text }} />
                                { ( appBadges ) && (
                                        <>
                                            <div className="d-flex justify-content-lg-end " style={ cssBadgesOptions }>
                                                <a href="!#" className="btn me-1">
                                                    <img src={ gplay } className="img-fluid" alt="gplay" />
                                                </a>
                                                <a href="!#" className="btn me-1">
                                                    <img src={ applestore } className="img-fluid" alt="applestore" />
                                                </a>
                                            </div>
                                        </>
                                    ) 
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default BBreadcrumb;