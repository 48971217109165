const Subscription = () => {
    return(
        <form className="subscription subscription__form">
            <h4 className="text-white mb-3">Newsletter</h4>
            <div className="col-12 input">
                <input type="mail" className="form-control" id="inputPassword2" placeholder="Email" />
                <button type="submit" className="btn btn-primary text-white">Enviar</button>
            </div>
        </form>
    );
}

export {Subscription}