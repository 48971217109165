import { NavList } from 'customTypes';
import bgndImage from '../../images/sidebar-menu/bike.png';

const Sidebar = (items:NavList) => {
    return (
        <aside className="sidebar" style={{ backgroundImage: `url(${bgndImage})` }}>
            <ul className="menu">
                {Object.values(items).map(({label, url, name}, i) => (
                    <li key={i}><a className="menu-link" href={ url }><div className="bullet"></div>{label}</a></li>
                ))}
            </ul>
        </aside>
    );
}

export default Sidebar;