import React from 'react'
import { Picks } from './pages/Picks'
import { Profile } from './pages/Profile'
import { Services } from './pages/Services'
import { Header } from './components/Header'
import { pageBG } from "../../images/tipster";
import { Route, Routes, Outlet } from 'react-router-dom'

const TipsterProfile: React.FC = () => {
  // const intl = useIntl();

  return (
    <Routes>
      <Route
        element={
          <>
            <div style={{
              backgroundSize: 'cover',
              backgroundPositionY: '-150px',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${pageBG})`,
            }}>
                <div className="container" style={{
                    marginTop: '110px',
                    paddingTop: '20px',
                }}>
                    <div className="row">
                      <Header />
                      <Outlet />
                    </div>
                </div>
            </div>
          </>
        }
      >
        <Route
          path='/'
          element={ <Profile /> }
        />
        <Route
          path='picks/:id'
          element={
            <>
              <Picks />
            </>
          }
        />
      <Route
          path='services/:id'
          element={
            <>
              <Services />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export { TipsterProfile }
