import patternLeft from '../../images/patterns/pattern-left.png';

const Confidence = () => {
    return(
        <div className="why-container position-relative py-5">
            <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-270px', left: '-110px' }}/>
            <div className="mt-5 row">
                <h2 className="title fs-1 fw-bold text-center">¿Por qué confiar <br />en <span>apuestes?</span></h2>
            </div>
            <div className="row mb-3 justify-content-center">
                <div className="col-md-7"></div>
                <div className="col-md-5 d-flex justify-content-start">
                    <div className="step-box">
                        <h1 className="title title__pop--huge">1</h1>
                        <div className="content--1">
                            <h5 className="title fw-bold">Verificación</h5>
                            <p className="text">Lorem ipsum dolor sit amet, consectetur <span className="fw-bold">adipiscing elit</span>, ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3 justify-content-center">
                <div className="col-md-5 d-flex justify-content-end">
                    <div className="step-box">
                        <h1 className="title title__pop--huge">2</h1>
                        <div className="content--2">
                            <h5 className="title fw-bold">Envíos en <br/> tiempo real</h5>
                            <p className="text">Lorem ipsum dolor sit amet, consectetur <span className="fw-bold">adipiscing elit</span>, ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-7"></div>
            </div>
            <div className="row mb-3 justify-content-center">
                <div className="col-md-7"></div>
                <div className="col-md-5 d-flex justify-content-start">
                    <div className="step-box">
                        <h1 className="title title__pop--huge">3</h1>
                        <div className="content--2">
                            <h5 className="title fw-bold">Transparencia</h5>
                            <p className="text">Lorem ipsum dolor sit amet, consectetur <span className="fw-bold">adipiscing elit</span>, ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3 justify-content-center">
                <div className="col-md-5 d-flex justify-content-end">
                    <div className="step-box">
                        <h1 className="title title__pop--huge">4</h1>
                        <div className="content--2">
                            <h5 className="title fw-bold">Trayectoria</h5>
                            <p className="text">Lorem ipsum dolor sit amet, consectetur <span className="fw-bold">adipiscing elit</span>, ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-7"></div>
            </div>
        </div>
    );
}

 export default Confidence;