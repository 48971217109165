import React from 'react';
import { User } from '@apuestes/types';
import { banner } from '../images/tipster';
import patternLeft from '../images/patterns/pattern-left.png';
import BBreadcrumb from '../components/banner-breadcrumb/BannerBreadcrumb';
import { TipsterCard, TipstersLayout } from '../modules/tipsters';
import { Download } from '../modules/contacto/Download';
import { RangeSlide } from '../components/range-slide/RangeSlide';

type TipsterProps = {
    tipster?: User;
}

const Tipsters: React.FC<TipsterProps> = ({ tipster }) => {
    return (
        <div className='tipster' style={{
            marginTop: '120px'
        }}>
            <BBreadcrumb
                image={banner}
                height={425}
                text='<span class="fw-bold">¡Sigue a los mejores<br/> tipster profesionales,</span><br/> y gana con nosotros!'
                appBadges={true}
                cssOptions={{ backgroundSize: 'cover', marginBottom: '1.75rem' }} />
            <div className="row">
                <h1 className="title title__pop">Tipsters</h1>
            </div>
            <div className="position-relative container">
                <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-260px', left: '-20%', zIndex: '-1', height: '750px' }} />
                <div className="my-4 row">
                    <div className="col-lg-6">
                        <div className="rounded-5 input-group input-group-lg shadow border-primary mb-3">
                            <input type="text" className="form-control border-0" placeholder="Buscar por nombre" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button className="btn btn-primary" type="button" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                            </button>
                        </div>
                        <div className="rounded-5 input-group input-group-lg shadow border-primary mb-3">
                            <input type="text" className="form-control border-0" placeholder="Yield (más de 100 picks)" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button className="btn btn-primary" type="button" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                            </button>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="w-50 form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                <label className="form-check-label text-muted" htmlFor="flexSwitchCheckDefault">Solo mis tipsters</label>
                            </div>
                            <div className="w-50 form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" />
                                <label className="form-check-label text-muted" htmlFor="flexSwitchCheckDefault2">Solo tipsters activos</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <form id='tipster-filter-form' className='shadow rounded-5'>
                            <div className="d-flex mb-3">
                                <div className="col-5 border-end border-primary">
                                    <select id='sport-select' className="form-select form-control-lg bg-primary border-end border-primary text-white" aria-label="Default select example">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <div id='date-filter' className="p-3 px-4 bg-primary-light border-bottom border-primary">
                                        <label className='w-100 fs-7 mb-2 d-flex align-items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                            <span className="fw-bold">
                                                Período
                                            </span>
                                        </label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input small" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label className="form-check-label small" htmlFor="inlineRadio1">1</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input small" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                            <label className="form-check-label small" htmlFor="inlineRadio2">2</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input small" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                            <label className="form-check-label small" htmlFor="inlineRadio3">3 (disabled)</label>
                                        </div>
                                    </div>
                                    <div id='bookie-filter' className="p-3 px-4 bg-primary-light">
                                        <label className='w-100 fs-7 mb-2 d-flex align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                            <span className="fw-bold">
                                                Bookie
                                            </span>
                                        </label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input small" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label className="form-check-label small" htmlFor="inlineRadio1">1</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input small" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                            <label className="form-check-label small" htmlFor="inlineRadio2">2</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input small" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                            <label className="form-check-label small" htmlFor="inlineRadio3">3 (disabled)</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3 col-7">
                                    <div id='pick-filter' className="pt-1 px-4">
                                        <label className='w-100 fs-7 mb-2 d-flex align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                            <span className="fw-bold">
                                                No. de picks
                                            </span>
                                        </label>
                                        <RangeSlide />
                                    </div>
                                    <div id='yield-filter' className="pt-1 px-4">
                                        <label className='w-100 fs-7 mb-2 d-flex align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.2 7.8l-7.7 7.7-4-4-5.7 5.7"/><path d="M15 7h6v6"/></svg>
                                            <span className="fw-bold">
                                                % de yield
                                            </span>
                                        </label>
                                        <RangeSlide />
                                    </div>
                                    <div id='winrate-filter' className="pt-1 px-4">
                                        <label className='w-100 fs-7 mb-2 d-flex align-items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                            <span className="fw-bold">
                                                Win Rate
                                            </span>
                                        </label>
                                        <RangeSlide />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Listado de tipsters */}
            <div className="container mt-5 pt-5 pt-lg-0 mt-lg-0">
                <div className="ranked-tipsters my-md-5 py-md-5 row justify-content-center">
                    {/* Iterar sobre los tipsters */}
                    {/* Nota: el componente TipsterCard recibe un prop tipster, que es un objeto con la información del tipster. */}
                    {/* Nota: cambiar key por el id y nombre del tipster */}
                    {Array.from(Array(12).keys()).map((item)=> (
                        <TipsterCard key={item} containerClass='col-xl-4 col-md-6 pb-md-5 mb-md-5' />
                    ) )}
                </div>
            </div>
            <TipstersLayout />
            <Download />
        </div>
    );
};

export { Tipsters };