import { futbolIcon } from "../../../../images/sports";
import { spainFlag } from "../../../../images/flags";
import { calendarIcon, clockIcon, dollarBillIcon, marketIcon, settingsIcon } from "../../../../images/tipster";
import { FC } from "react";
import moment from "moment";
type Props = {
    obj: any
};

const PickCard: FC<Props> = ({ obj }) => {
    return (
        <>
            <div className="card border-primary mb-3">
                <div className="row">
                    <div className="pe-xl-0 col-xl-3 border-primary border-xl-end">
                        <div className="d-flex flex-column">
                            <div className="col-12 p-2 d-flex flex-column align-items-center justify-content-center bg-primary-light rounded-top border-bottom border-primary">
                                <p className="ffs-8px my-2 text-muted">Publicado por <b>Chusso8Draws</b><br /> El 02/06/2022 a las 11:33</p>
                            </div>
                            <div className="col-12 d-flex align-items-center justify-content-center p-3 bg-primary-light border-bottom border-primary">
                                <img src={futbolIcon.default} alt="" />
                                <img className="mx-1" src={spainFlag.default} alt="" />
                                <span className="badge rounded-pill border border-primary text-dark text-uppercase fs-10px">Previo</span>
                            </div>
                            <div className="col-12 d-flex flex-column rounded-bottom p-3 py-4 bg-primary-light">
                                <p className="mb-2 fw-bold ffs-9px"><img src={ clockIcon.default } alt="" /> { moment( obj.date, 'MMMM Do YYYY, h:mm:ss a' ).fromNow() }</p>
                                <p className="mb-0 fw-bold ffs-9px"><img src={calendarIcon.default} alt="" /> 17:00 H</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12">
                                <div className="d-flex align-items-center justify-content-center">
                                    <label className="fw-bold fs-4">Equipo 1</label>
                                    <h1 className="title mx-3 title__pop title__pop--xsmall">VS</h1>
                                    <label className="fw-bold">Equipo 2</label>
                                </div>
                            </div>
                            <p className="w-75 mb-0 ffs-10px text-muted text-center">PRIMERA RFEF G2</p>
                            <div className="p-2 mt-3 col-md-9 bg-primary-light rounded-pill">
                                <p className="mb-0 ffs-11px text-dark fw-bold text-center">Resultado exacto 1-1</p>
                            </div>
                            <div className="w-100 mt-3 d-flex align-items-center justify-content-around">
                                <button type="button" className="min-w-125px min-w-xl-175px btn btn-sm btn-outline-primary rounded-pill">BTN COMPRA €</button>
                                <button type="button" className="min-w-125px min-w-xl-175px btn btn-sm btn-outline-primary rounded-pill">BET365</button>
                            </div>
                            <div className="w-100 mt-3 d-flex align-items-center justify-content-center">
                                <span className="mx-1 ffs-10px badge rounded-0 border border-primary-light text-dark d-flex align-items-center">
                                    <img src={marketIcon.default} alt="" /> Bet365
                                </span>
                                <span className="mx-1 ffs-10px badge rounded-0 border border-primary-light text-dark">
                                    <img src={settingsIcon.default} alt="" /> STK 1/10
                                </span>
                                <span className="mx-1 py-1 ffs-10px badge rounded-0 border border-primary-light text-dark">
                                    <img src={dollarBillIcon.default} alt="" /> ODD 6
                                </span>
                                <span className="mx-1 py-1 ffs-10px badge rounded-0 border border-primary-light text-dark">+5</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { PickCard };