import { shielPremiumIcon, shielFreeIcon } from '../../../../images/tipster';
import { shield, soccerBall } from '../../../../images/home/tipsters';
import { spainFlag } from '../../../../images/flags';

type Props = {};

const Shield = (props: Props) => {
    const premium = true;
    return (
        <div className='px-3 py-4' style={{
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${premium ? shielPremiumIcon : shielFreeIcon})`,
            height: '350px',
            width: '240px'
        }}>
            <div className="mt-2 col-12">
                {premium ? (<h1 className="title title__pop title__pop--xsmall transparent-shadow">premium</h1>) : (<h1 className="title title__pop title__pop--xsmall transparent-shadow">free</h1>)}
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="ps-4 pe-2 col-5 d-flex justify-content-center flex-wrap">
                        <div className="w-85 h-65 border border-white rounded d-flex flex-column align-items-center justify-content-center">
                            <img src={ soccerBall } className="my-2 img-fluid soccer-ball" width="25" height="25" alt="" />
                            <div className="w-100 py-1 d-flex align-items-center justify-content-center border-top border-white text-white">
                                Futbol
                            </div>
                        </div>
                        <img className='mt-1' src={ spainFlag.default } alt="" width={ 50 } />
                    </div>
                    <div className="ps-1 pe-3 col-7">
                        <div className="img-box bg-white rounded-circle w-105px top-5px right-3px position-relative">
                            <img src={shield} className="img-fluid" alt="Chuso" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 mt-2 col-12">
                <div className="row border-white border-top border-bottom">
                    <h5 className="my-2 text-center fw-bold">Chusso8Draws</h5>
                </div>
            </div>
            <div className="px-3 col-12">
                <div className="row">
                    <div className="pb-4 col-6 d-flex justify-content-center flex-wrap">
                        <label htmlFor="tipster_pick_label" className="fw-semibold text-uppercase">Picks</label>
                        <h4 id='tipster_pick_label' className="mb-0 fw-bold text-white text-center">1.756</h4>
                    </div>
                    <div className="pb-4 col-6 border-white border-start d-flex justify-content-center flex-wrap">
                        <label htmlFor="tipster_yield_label" className="fw-semibold text-uppercase">Yield</label>
                        <h4 id='tipster_yield_label' className="mb-0 fw-bold text-white text-center">15.8%</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Shield };