import userIcon from '../../images/contacto/user.svg';
import phoneIcon from '../../images/contacto/phone.svg';
import mailIcon from '../../images/contacto/mail.svg';

import Input from './form-inputs/Input';
// import Select from './form-inputs/Select';
import TextArea from './form-inputs/TextArea';

const TipsterContactForm = () => {
    return(
        <>
            <form>
                <Input type={"text"} label={"Nombre y Apellidos"} name={"full_name"} icon={userIcon} />
                <Input type={"tel"} label={"Teléfono"} name={"phone"} icon={ phoneIcon } />
                <Input type={"email"} label={"Email"} name={"mail"} icon={mailIcon} />
                <TextArea label={"Mensaje"} name={"mail"} />
                <div className="form-check">
                    <input className="form-check-input border-primary" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label small" htmlFor="flexCheckDefault">
                        Acepto los términos y condiciones.
                    </label>
                </div>
                <div className="d-flex my-5 justify-content-center">
                    <button type="submit" className="btn btn-dark rounded-pill py-2 px-5 text-uppercase">Enviar</button>
                </div>
            </form>
        </>
    );
}

export {TipsterContactForm};