import patternLeft from '../images/patterns/pattern-left.png';
// import patternRight from '../images/patterns/pattern-right.png';
import bannerImage from '../images/hazte-tipster/banner.jpg';
import BBreadcrumb from '../components/banner-breadcrumb/BannerBreadcrumb';
import {TipsterContactForm} from '../components/contact-form';
import { Steps as StepGrid, OptionCard } from '../modules/tipsters';

const options = [
    { 
        id: 1,
        header: 'COMPARTE TUS PICKS <span>GRATIS</span>',
        title: 'FREE',
        subtitle: 'Acceso Libre',
        text: '<span>Empieza tu carrera</span> de tipster con nosotros. Gana experiencia y conviértete en un tipster premium.', 
        buttonText: '¡HAZTE TIPSTER!'
    },
    { 
        id: 2,
        header: 'COMISIONES DE HASTA <span>50%</span>',
        title: 'TALENT',
        subtitle: 'Acceso desde 100 picks',
        text: 'Tipsters con <span>gran potencial desde sus inicios</span>. Consigue una oportunidad para vender tus pronósticos.', 
        buttonText: '¿CÓMO ACCEDER?'
    },
    { 
        id: 3,
        header: 'COMISIONES DE HASTA <span>80%</span>',
        title: 'PREMIUM',
        subtitle: 'Acceso desde 400 picks',
        text: 'Tipsters <span>profesionales</span>, con buen rendimiento en el largo plazo. Una apuesta segura en apuestes.', 
        buttonText: '¿CÓMO ACCEDER?'
    }
  ]

const steps = [
    { 
        step: 1,
        title: 'Experiencia',
        text: 'Control y evaluación de tus pronósticos con objetivos para poder progresar como tipster. <span>Más de 5 años asesorando a los mejores tipsters</span>', 
        hasButton: false,
        buttonText: ''
    },
    { 
        step: 2,
        title: 'Transparencia',
        text: 'Dispondrás de una página interna con las <span>comisiones a percibir en tiempo real</span>, de manera que sepas en cada momento lo que te corresponde.', 
        hasButton: false,
        buttonText: ''
    },
    { 
        step: 3,
        title: 'Perfil <br/> de tipster',
        text: '<span>El perfil estadístico más completo</span> de la red, disponible en tu perfil de Tipster.', 
        hasButton: true,
        buttonText: 'Ver un perfil'
    },
    { 
        step: 4,
        title: 'Trabaja<br/> con nosotros',
        text: '¿Tienes un perfil anterior en una web de confianza? <span>Importamos tus picks anteriores</span> y además podrás acceder directamente a ser Tipster Talent o Premium', 
        hasButton: true,
        buttonText: 'Solicitar Acceso'
    },
]

const HazteTipster = () => {
    return(
        <div className="hazte-tipster-page position-relative">
            <BBreadcrumb image={bannerImage} height={400} />
            <div className="htipster htipster__container container position-relative">
                <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-150px', left: '-90px' }}/>
                <div className="row my-5 justify-content-center">
                    <h2 className="title fs-1 fw-bold text-center">Demuestra tu <br />talento como <span>tipster</span></h2>
                    <p className="text-center">Crea tu perfil, sube tus apuestas y conviértete en<br/> un tipster premium de Apuestes.</p>
                </div>
                <div className="row px-4 my-5 py-4 justify-content-center">
                    {options.map( ({id, header, title, subtitle, text, buttonText}) =>(
                        <OptionCard 
                            key={id}
                            header={header}
                            title={title} 
                            subtitle={subtitle} 
                            text={text} 
                            buttonText={buttonText} />
                    ))}
                </div>
            </div>
            <div className="w-100 px-3 htipster-why position-relative py-5">
                <div className="mt-5 row">
                    <h2 className="title fs-1 fw-bold text-center">¿Que te ofrecemos <br />Como <span>tipster?</span></h2>
                </div>
                <>
                    <StepGrid stepList={steps} />
                </>
                <div className="w-100 mt-5 py-5 row">
                    <h1 className="title title__pop z-1 mb-3">Contacto</h1>
                </div>
                <div className="row py-5 align-items-start justify-content-center">
                    <div className="px-3 col-lg-3 d-flex flex-column align-items-start justify-content-center">
                        <h3 className="fw-bold contact-message-title">Resolvemos tus dudas</h3>
                        <p>Rellena el siguiente formulario y nos pondremos en contacto lo más rápido posible.</p>
                    </div>
                    <div className="px-3 col-lg-6">
                        <TipsterContactForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export {HazteTipster};