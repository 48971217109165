import React from 'react';
import gplay from '../../images/slider/google-play.png';
import applestore from '../../images/slider/apple-store.png';
import handphone from '../../images/contacto/handphone.png';

type DownloadProps = {
    cssClass?: string;
    cssOptions?: Object;
}
const Download: React.FC<DownloadProps> = ({ cssClass, cssOptions }) => {
    return(
        <>
            <div className={'w-100 pt-5 pt-lg-0 d-flex flex-wrap ' + cssClass}>
                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                    <div className="message-box">
                        <h1 className="fs-2"><span className="fw-bold">¡Descarga <br/> nuestra app!</span> <br/><span className="fw-light">Y sigue a los mejores <br/> tipster profesionales!</span></h1>
                        <div className="d-flex">
                            <a href="https://play.google.com/" className="btn me-1">
                                <img src={ gplay } height="35" alt="" />
                            </a>
                            <a href="https://www.apple.com/store" className="btn me-1">
                                <img src={ applestore } height="35" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 position-relative">
                    <img src={ handphone } className="handphone" alt="" />
                </div>
            </div>
        </>
    );
};

export { Download };