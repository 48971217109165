
import wstar from '../../../images/feedback/white-star.png';
import fstar from '../../../images/feedback/filled-star.svg';

const FeedbackCard: React.FC<{ userAvatar: string, }> = (props) => {
    const { userAvatar } = props;
    return(
        <>
            <div className="feedback__card card border-0">
                <div className="card-body">
                    <div className="meta-box d-flex align-items-center justify-content-center">
                        <div className="img-box">
                            <img src={ userAvatar } alt="" className="img-fluid" />
                        </div>
                        <div className="meta ps-3 d-flex flex-column align-items-start justify-content-center">
                            <h5 className="card-title fs-6 fw-bold">Fidel</h5>
                            <h6 className="card-subtitle mb-2 text-muted">
                                <ul className="nav bg-transparent">
                                    <li>
                                        <img src={ wstar } className="img-fluid" alt="" width="17" height="18" />
                                    </li>
                                    <li>
                                        <img src={ fstar } className="img-fluid" alt="" width="17" height="18" />
                                    </li>
                                    <li>
                                        <img src={ fstar } className="img-fluid" alt="" width="17" height="18" />
                                    </li>
                                    <li>
                                        <img src={ fstar } className="img-fluid" alt="" width="17" height="18" />
                                    </li>
                                    <li>
                                        <img src={ fstar } className="img-fluid" alt="" width="17" height="18" />
                                    </li>
                                </ul>
                            </h6>
                        </div>
                    </div>
                    <p className="card-text text-center"><span>“</span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco. <span>”</span></p>
                </div>
            </div>
        </>
    );
}

export {FeedbackCard};