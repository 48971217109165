import { ServiceItem } from 'customTypes';

const ServiceCard = (item: ServiceItem) => (
    <div className="col-lg-3">
        <div className="service__card card mx-2 border-0 shadow p-4 d-flex align-items-center">
            <div className="img-box rounded-circle">
                <img src={item.image} className="img-fluid position-absolute" width="70" height="70" alt="Search Logo" />
            </div>
            <div className="card-body pt-5">
                <h5 className="card-title text-center fw-bold fs-2">{item.name}</h5>
                <p className="card-text text-center" dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
        </div>
    </div>
);


export default ServiceCard