import { FC } from "react";

type Props = {
    obj: any
};

const ServiceCard:FC<Props> = ({ obj }) => {
    return (
        <>
            <div className="card shadow rounded border-0 mb-3">
                <div className="card-header border-0 bg-primary text-white">
                    <img className="me-1" src={ obj.icon } alt="" width={20} height={20} />
                    { obj.name }
                </div>
                <div className="card-body rounded-bottom bg-white">
                    <h5 className="card-title">Primary card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
            </div>
        </>
    );
};

export { ServiceCard };