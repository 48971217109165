import moment from 'moment';
import { PickCard } from '../components'
import { useEffect, useState } from 'react';

const picks = [
    {
        id: 1,
        date: moment().add(3, 'days')
    },
    {
        id: 2,
        date: moment().add(2, 'weeks')
    },
    {
        id: 3,
        date: moment().subtract(1, 'days')
    },
    {
        id: 4,
        date: moment().subtract(4, 'days')
    },
    {
        id: 5,
        date: moment().subtract(2, 'days')
    }, {
        id: 6,
        date: moment().subtract(8, 'days')
    }
]

const Picks = () => {
    const sortedArray = picks.sort((a, b) => {
        return moment(b.date).diff(a.date);
    }) // Sort dates from now to oldest days

    const [activePick, setActivePicks] = useState<any>([])
    const [oldestPick, setOldestPicks] = useState<any>([])
    const [toggle, setToggle] = useState<boolean>()

    useEffect(() => {
        const actives = sortedArray.filter(el => {
            return moment().isBefore(moment(el.date)) && el
        })
        setActivePicks(actives)

        const oldest = sortedArray.filter(el => {
            return moment().isAfter(moment(el.date)) && el
        })
        setOldestPicks(oldest)

    }, [sortedArray])

    return (
        <>
            <div className="container mb-5 position-relative">
                <div className="row mb-4 align-items-center">
                    <div className="w-125px position-xl-absolute start-0">
                        <div className="form-check form-switch position-relative">
                            <input
                                className="form-check-input huge vertical"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onChange={(e) => {
                                    setToggle(e.target.checked)
                                }} />
                            <div className="d-grid">
                                <label className="form-check-label mb-2" htmlFor="flexSwitchCheckDefault">Activos</label>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Historial</label>
                            </div>
                        </div>
                    </div>
                    { !toggle ? <h1 className="title title__pop title__pop--small">Picks Activos</h1> : <h1 className="title title__pop title__pop--small">Historial</h1> }
                </div>
                <div className="row">
                    {Array.from(activePick).map((el: any, idx: number) => (
                        <div key={idx} className="col-md-6">
                            <PickCard obj={el} />
                        </div>
                    )
                    )}
                </div>
                { toggle && (
                    <>
                        <hr className='mb-5' />
                        <div className="row">
                            {Array.from(oldestPick).map((el: any, idx: number) => (
                                <div key={idx} className="col-md-6">
                                    <PickCard obj={el} />
                                </div>
                            )
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export { Picks };