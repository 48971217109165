import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
/* Styles */
import 'bootstrap';
import './styles/_core.scss';
/* Pages */
import {Home, Faqs, Contacto, HazteTipster, Tipsters, ErrorPage, TipsterProfile } from './pages';
/* Components */
import {Navbar, Footer} from './components/common';
import reportWebVitals from './reportWebVitals';

const items = [
  { name: 'picks', label: 'Picks', url: '/picks'},
  { name: 'tipsters', label: 'Tipsters', url: '/tipsters'},
  { name: 'hazte-tipster', label: 'Hazte tipster', url: '/hazte-tipster'},
  { name: 'blog', label: 'Blog', url: '/blog'},
  { name: 'sobre-nosotros', label: 'Sobre nosotros', url: '/sobre-nosotros'},
  { name: 'contacto', label: 'Contacto', url: '/contacto' },
  { name: '', label: 'Faqs', url: '/faqs' }]

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <Router>
          <Navbar {...items} />
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/faqs" element={<Faqs/>} />
              <Route path="/contacto" element={<Contacto/>} />
              <Route path="/tipsters" element={<Tipsters/>} />
              <Route path="/tipster/*" element={<TipsterProfile />} />
              <Route path="/hazte-tipster" element={<HazteTipster/>} />
              <Route path='*' element={<ErrorPage error="404" message="Not Found" />} />
            </Routes>
          <Footer {...items}/>
        </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
