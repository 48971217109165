const TextArea: React.FC<{ label: string, name: string }> = (props) => {
    const { label, name } = props;
    return(
        <>
            <div className="form-floating mb-3">
                <textarea className="form-control border-primary" id={name} name={name} placeholder="Leave a comment here" style={{ height: '100px' }}></textarea>
                <label htmlFor="exampleFormControlTextarea1" className="form-label"><span className="fw-bold text-primary">{label}</span></label>
            </div>
        </>
    );
}

export default TextArea;