import { NavList } from 'customTypes';
import logoFooter from '../../images/footer/logo-footer.svg'
import facebook from '../../images/footer/facebook.svg'
import instagram from '../../images/footer/instagram.svg'
import twitter from '../../images/footer/twitter.svg'

import {Subscription} from '../contact-form';

const Footer = (navlist:NavList) => {
    return(
        <footer>
            <div className="container-fluid bg-dark">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-6 col-lg-3 mb-3 mb-lg-0 d-flex flex-column align-items-center d-lg-block">
                                <img src={ logoFooter } alt="" className="img-fluid" />
                                <div className="mt-3 d-flex justify-content-center">
                                    <a href="!#" className="btn">
                                        <img src={ facebook } className="img-fluid" alt="" />
                                    </a>
                                    <a href="!#" className="btn">
                                        <img src={ instagram } className="img-fluid" alt="" />
                                    </a>
                                    <a href="!#" className="btn">
                                        <img src={ twitter } className="img-fluid" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-3 mb-lg-0 text-footer">
                                <p className="mb-0 text-white" style={{ fontSize: '14px' }}>
                                <span className="fw-bold">Apuestes.com</span> es un <span className="fw-bold">Sistema de Gestión de Apuestas</span> pensado para sacar el mayor partido a tus pronósticos. Tanto si eres un profesional de las apuestas como si eres un inversor, <span className="fw-bold">esta es tu plataforma</span>.
                                </p>
                            </div>
                            <div className="col-md-6 col-lg-2 mb-3 mb-lg-0">
                            <ul className="menu">
                                {Object.values(navlist).map(({label, url, name}, i) => (
                                    <li key={i}><div className="bullet"></div><a className="menu-link" href={ url }>{label}</a></li>
                                ))}
                            </ul>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                <Subscription/>
                            </div>
                        </div>
                    </div>
                <div className="row bg-primary py-2">
                    <ul className="nav bg-transparent d-flex align-items-center justify-content-center">
                        <li>
                            <a href="!#" className="mx-3 text-decoration-none text-white fw-light">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="!#" className="mx-3 text-decoration-none text-white fw-light">Legal Advise</a>
                        </li>
                        <li>
                            <a href="!#" className="mx-3 text-decoration-none text-white fw-light">Cookies Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export {Footer};