import React from 'react';
// import { useAxios } from '../../hooks';
import { TipsterCard, TipsterFilter } from './cards';
import patternRight from '../../images/patterns/pattern-right.png';

type TipstersLayoutProps = {
    pattern?: boolean;
}
const TipstersLayout: React.FC<TipstersLayoutProps> = ({ pattern }) => {
    const cardTypes = ['premium', 'free', 'premium'];
    // const { data, error, loading } = useAxios( process.env.REACT_APP_API_URL + '/tipsters' );
    
    return(
        <div className="row tipsters tipsters__container pt-5" style={{ backgroundColor: 'rgb(231 55 88 / 21%)' }}>
            <div className="container-fluid position-relative">
                { pattern && (
                        <img src={ patternRight } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-325px', right: '-90px' }}/>
                    )
                }
                <div className="container">
                    <div className="row">
                        <div className="col-8 col-xl-7 d-flex justify-content-center justify-content-lg-end">
                            <h1 className="title title__pop--semi">Tipsters</h1>
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center justify-content-lg-start">
                            <h1 className="mb-0 fw-bold">Los mejores<br/> <span className="accent">a un solo click</span></h1>
                        </div>
                    </div>
                    <TipsterFilter />
                    <div className="ranked-tipsters row justify-content-center">
                        {/* Iterar sobre los tipsters */}
                        {/* Nota: el componente TipsterCard recibe un prop tipster, que es un objeto con la información del tipster. */}
                        {/* Nota: cambiar key por el id y nombre del tipster */}
                        {cardTypes.map((item)=> (
                            <TipsterCard key={item} containerClass='col-lg-4' />
                        ) )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { TipstersLayout };