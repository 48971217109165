import { Splide, SplideSlide } from '@splidejs/react-splide';
import slide1 from '../../images/slider/slide1.jpg'
import slide2 from '../../images/slider/slide2.jpg'
import gplay from '../../images/slider/google-play.png'
import applestore from '../../images/slider/apple-store.png'
import facebook from '../../images/social-networks/facebook.png'
import instagram from '../../images/social-networks/instagram.png'
import twitter from '../../images/social-networks/twitter.png'

function Slider() {
  return (
    <div className="home-page position-relative">
        <Splide options={ {
                type    : 'slide',
                height  : '95vh',
                autoplay: true,
                interval: 5000,
                arrows  : false,
                rewind  : true,
            } } aria-label="My Favorite Images">
            <SplideSlide>
                <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center align-items-lg-end align-items-xl-center justify-content-center">
                    <div className="mt-4 container">
                        <div className="row">
                            <h1 className="text-white display-2"><span className="fw-bold">Descarga nuestra app</span> <br/><span className="fw-light">¡Y entérate de todo!</span></h1>
                        </div>
                        <div className="d-flex">
                            <a href="!#" className="btn me-1">
                                <img src={ gplay } className="img-fluid" alt="gplay" />
                            </a>
                            <a href="!#" className="btn me-1">
                                <img src={ applestore } className="img-fluid" alt="applestore" />
                            </a>
                        </div>
                    </div>
                </div>
                <img src={ slide1 } alt="slide1"/>
            </SplideSlide>
            <SplideSlide>
                <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center align-items-lg-end align-items-xl-center justify-content-center">
                    <div className="mt-4 container">
                        <div className="row">
                            <h1 className="text-white display-2"><span className="fw-bold">¡Sigue a los mejores<br/> tipster profesionales,</span> <br/><span className="fw-light"> y gana con nosotros!</span></h1>
                        </div>
                        <div className="d-flex">
                            <a href="!#" className="btn me-1">
                                <img src={ gplay } className="img-fluid" alt="gplay" />
                            </a>
                            <a href="!#" className="btn me-1">
                                <img src={ applestore } className="img-fluid" alt="applestore" />
                            </a>
                        </div>
                    </div>
                </div>
                <img src={ slide2 } alt="slide2"/>
            </SplideSlide>
            <SplideSlide>
                <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center align-items-lg-end align-items-xl-center justify-content-center">
                    <div className="mt-4 container">
                        <div className="row">
                            <h1 className="text-white display-2"><span className="fw-bold">Descarga nuestra app</span> <br/><span className="fw-light">¡Y entérate de todo!</span></h1>
                        </div>
                        <div className="d-flex">
                            <a href="!#" className="btn me-1">
                                <img src={ gplay } className="img-fluid" alt="gplay" />
                            </a>
                            <a href="!#" className="btn me-1">
                                <img src={ applestore } className="img-fluid" alt="applestore" />
                            </a>
                        </div>
                    </div>
                </div>
                <img src={ slide1 } alt="slide3"/>
            </SplideSlide>
        </Splide>
        <div className="position-absolute top-50 end-0 me-2 d-flex flex-column">
            <a href="!#" className="btn mt-1">
                <img src={ facebook } className="img-fluid" alt="facebook" />
            </a>
            <a href="!#" className="btn mt-1">
                <img src={ instagram } className="img-fluid" alt="instagram" />
            </a>
            <a href="!#" className="btn mt-1">
                <img src={ twitter } className="img-fluid" alt="twitter" />
            </a>
        </div>
    </div>
  );
}

export default Slider;