import { useState } from 'react';
import { NavList } from 'customTypes';
import logo from '../../images/logo/logo.svg'
import Sidebar from '../navigation/Sidebar';

const Navbar = (items: NavList) => {
    const[show,setShow]=useState(false);
    return (
        <header className="w-100 position-fixed">
            <nav className="py-4 navbar shadow navbar-expand-lg bg-light">
                <div className="container">
                    <a className={ show?"navbar-brand hover":"navbar-brand" } href="/">
                        <img src={ logo } alt="Logo" className="d-inline-block img-fluid align-text-top" />
                    </a>
                    <div className="d-flex justify-content-end">
                        <input type="checkbox" id="toggle" className="input-toggler" />   
                        <label htmlFor="toggle" className="menu-toggler" onClick={()=>setShow(!show)}>
                            <span className="menu-toggler-line"></span>
                            <span className="menu-toggler-line"></span>
                            <span className="menu-toggler-line"></span>
                        </label>
                        <Sidebar {...items}/>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export {Navbar};