import React, { useEffect, useMemo, useState } from 'react';
// import { useIntl } from "react-intl";
import { User } from '@apuestes/types';
import { Shield } from './shield/Shield';
import { Link, useParams } from 'react-router-dom';
import { noValid, userLogo, valid } from '../../../images/home/tipsters';

type Props = {
    tipster?: User
};

const Header: React.FC<Props> = ({ tipster }) => {
    // const intl = useIntl()
    const[ id, setId ] = useState(useParams().id)
    const _searchquery = useMemo(() => {
        return new URLSearchParams(global.location.search)
    }, [])

    useEffect(() => {
        if (!id) {
            setId(_searchquery.get('id') || '')
        }
    }, [_searchquery, id])

    return (
        <>
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="d-flex justify-content-center justify-content-md-start col-md-5 col-lg-4 col-xl-3">
                        <Shield />
                    </div>
                    <div className="col-md-7 col-xl-9 pt-5">
                        <div className="tipster-main col-md-2">
                            <div className="row justify-content-start">
                                <div className="col-12 col-sm-3 d-flex align-items-center justify-content-center">
                                    <img src={userLogo} alt="" />
                                </div>
                                <div className="mt-3 mt-md-0 ms-md-3 ms-lg-0 tipster-stats col-12 col-sm-6 d-flex flex-row align-items-center justify-content-center flex-md-column">
                                    <h4 className="mb-0 text-center fw-bold">1766</h4>
                                    <p className="ms-2 ms-md-0 mb-0 text-center fw-light" style={{ fontSize: '10px' }}>Followers</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 col-12">
                            <div className="row justify-content-start">
                                <div className="col col-xl-1 py-1 px-2 bg-primary-light m-1 border border-primary">
                                    <div className="row flex-column align-content-center justify-content-center">
                                        <div className="col-auto text-success fw-bold fs-6">+567,21</div>
                                        <div className='col-auto fw-light small text-center'>Beneficios</div>
                                    </div>
                                </div>
                                <div className="col col-xl-1 py-1 px-2 bg-primary-light m-1 border border-primary">
                                    <div className="row flex-column align-content-center justify-content-center">
                                        <div className="col-auto fw-bold fs6">15,8%</div>
                                        <div className='col-auto fw-light small text-center'>Stake</div>
                                    </div>
                                </div>
                                <div className="col col-xl-1 py-1 px-2 bg-primary-light m-1 border border-primary">
                                    <div className="row flex-column align-content-center justify-content-center">
                                        <div className="col-auto fw-bold fs6">1.756</div>
                                        <div className='col-auto fw-light small text-center'>Cuota</div>
                                    </div>
                                </div>
                                <div className="col col-xl-1 py-1 px-2 bg-primary-light m-1 border border-primary">
                                    <div className="row flex-column align-content-center justify-content-center">
                                        <div className="col-auto fw-bold fs6">43,66%</div>
                                        <div className='col-auto fw-light small text-center'>Win rate</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 col-12 border-bottom border-primary">
                            <div className="mb-2 row">
                                <div className="col-lg-7 col-xxl-8 d-none d-xl-block">
                                    <div className="row">
                                        <ul className="nav bg-transparent">
                                            <li className='py-1 px-3'>
                                                <img src={noValid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={valid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={valid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={noValid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={valid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={valid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={valid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={noValid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={noValid} alt="" />
                                            </li>
                                            <li className='py-1 px-3'>
                                                <img src={valid} alt="" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-xxl-4">
                                    <div className="row justify-content-md-end justify-content-center my-3 my-lg-0">
                                        <div className="col-auto">
                                            <button className="px-4 text-white text-uppercase btn btn-primary btn-sm rounded-5">Susucribir</button>
                                        </div>
                                        <div className="col-auto">
                                            <button className="px-4 text-white text-uppercase btn btn-primary btn-sm rounded-5">Contratar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 border-bottom border-primary">
                            <div className='d-flex overflow-auto h-55px'>
                                <ul className='nav bg-transparent justify-content-around  fs-5 fw-bolder flex-nowrap'>
                                    <li className='nav-item'>
                                        <Link
                                            className={
                                                `nav-link me-6 ` +
                                                (global.location.search.includes('?id=') && 'text-primary border-2 border-primary border-bottom')
                                            }
                                            to={'/tipster?id=' + 1}
                                        >
                                            Stats
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link
                                            className={
                                                `nav-link me-6 ` +
                                                (global.location.pathname.includes('/tipster/picks') && 'text-primary border-2 border-primary border-bottom')
                                            }
                                            to={'/tipster/picks/' + 1}
                                        >
                                            Picks
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link
                                            className={
                                                `nav-link me-6 ` +
                                                (global.location.pathname.includes('/tipster/services') && 'text-primary border-2 border-primary border-bottom')
                                            }
                                            to={'/tipster/services/' + 1}
                                        >
                                            Servicio
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 py-3 py-lg-2">
                            <ul className="nav nav-pills bg-transparent justify-content-between">
                                <li className="nav-item">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Mes</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Deporte</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Competición</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Mercado</a>
                                </li>
                                <li className="nav-item d-none d-xl-block">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Stake</a>
                                </li>
                                <li className="nav-item d-none d-xl-block">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Cuotas</a>
                                </li>
                                <li className="nav-item d-none d-xl-block">
                                    <a className="nav-link px-3 badge border border-primary bg-light rounded-pill text-dark text-uppercase fw-light" href="!#">Bookie</a>
                                </li>
                                <li className="nav-item d-none d-xl-block">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label className="form-check-label" htmlFor="inlineRadio1">1</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                            <label className="form-check-label" htmlFor="inlineRadio2">2</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                            <label className="form-check-label" htmlFor="inlineRadio3">3 (disabled)</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { Header };