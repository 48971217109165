import React from 'react';
import { shield } from '../../../../images/home/tipsters';

type Props = {};

const ChannelCard = (props: Props) => {
    return (
        <>
            <div className="card border-0 mb-3">
                <div className="row g-0">
                    <div className="col-md-4 d-flex align-items-start justify-content-center">
                        <img src={ shield } className="img-fluid top-1 position-absolute rounded-circle border border-dark" width={55} height={55} alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h5 className="card-title text-primary">Card title</h5>
                            <p className="card-text ffs-10px">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                            <button type="button" className="btn btn-primary btn-sm px-3 rounded-pill text-text-uppercase text-white">Contratar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { ChannelCard };