const Input: React.FC<{ type: string, label: string, name: string, icon: string }> = (props) => {
    const { type, label, name, icon } = props;
    return(
        <>
            <div className="input-group mb-3">
                <span className="bg-white border border-primary border-right-0 input-group-text">
                    <img src={ icon } alt="" className="img-fluid" />
                </span>
                <div className="form-floating">
                    <input type={ type } className="form-control border-primary" name={ name } id={ name } placeholder="Username" />
                    <label htmlFor="floatingInputGroup1">{ label }</label>
                </div>
            </div>
        </>
    );
}

export default Input;