export const eyeIcon = require('./eye.svg');
export const banner = require('./banner.png');
export const clockIcon = require('./clock.svg');
export const marketIcon = require('./market.svg');
export const pageBG = require('./tispster-bg.png');
export const stakeIcon = require('./stake-graph.svg');
export const calendarIcon = require('./calendar.svg');
export const settingsIcon = require('./settings.svg');
export const serviceIcon = require('./service-icon.svg');
export const shielFreeIcon = require('./shield-free.png');
export const dollarBillIcon = require('./dollar-bill.svg');
export const serviceMarketIcon = require('./market-icon.svg');
export const serviceClockIcon = require('./service-clock.svg');
export const shielPremiumIcon = require('./shield-premium.png');