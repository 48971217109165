import { Chart } from "../components/charts/Chart";

const Profile = () => {
    return (
        <>
            <div className="mb-5 mt-3 container">
                <div className="row mb-4">
                    <div id="chart-parent" className="col-12">
                        <Chart />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead className="text-white">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Mes</th>
                                    <th scope="col">Picks</th>
                                    <th scope="col">Beneficio</th>
                                    <th scope="col" className="d-none d-lg-table-cell">Yield</th>
                                    <th scope="col" className="d-none d-lg-table-cell">Apostado</th>
                                    <th scope="col">Acierto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Julio 2022</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td className="d-none d-lg-table-cell">@mdo</td>
                                    <td className="d-none d-lg-table-cell">@mdo</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Junio 2022</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td>@fat</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Mayo 2022</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td>@fat</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Abril 2022</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td>@fat</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>Marzo 2022</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td>@fat</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>Febrero 2022</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td className="d-none d-lg-table-cell">@fat</td>
                                    <td>@fat</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export { Profile };