// import { User } from '@apuestes/types'
import { graphic, noValid, shield, userLogo, soccerBall, valid } from '../../../images/home/tipsters';

type TipsterCardProps = {
    containerClass?: string
}

const TipsterCard: React.FC<TipsterCardProps> = ({ containerClass }) => {

    return (
        <div className={`${ containerClass } position-relative d-flex flex-column align-items-center`}>
            <div className="img-box rounded-circle tipsters avatar">
                <img src={shield} className="img-fluid" alt="Chuso" />
            </div>
            <div className={'free' ? "tipsters__card--free card mx-1 border-0 d-flex align-items-center" : "tipsters__card card mx-1 border-0 d-flex align-items-center"}>
                <div className="w-100 card-body">
                    <div className="background-box d-flex align-items-center justify-content-end flex-column">
                        <div className="row">
                            <h2 className="text-white text-center mb-0">Chusso8Draws</h2>
                            <p className="text-center text-white fw-light mb-0">Premium</p>
                        </div>
                        <div className="w-100 mb-2 d-flex flex-wrap px-3">
                            <div className="col-3 px-2 d-flex flex-wrap align-items-end justify-content-center">
                                <div className="w-100 mb-2 border border-white rounded d-flex flex-column align-items-center justify-content-center">
                                    <img src={soccerBall} className="my-2 img-fluid soccer-ball" width="40" height="40" alt="" />
                                    <div className="w-100 py-1 d-flex align-items-center justify-content-center border-top border-white text-white">
                                        Futbol
                                    </div>
                                </div>
                                <a href="!#" className="btn btn-dark rounded-pill">Seguir</a>
                            </div>
                            <div className="col-9 px-1 d-flex align-items-end">
                                <div className="w-100 graghpics position-relative">
                                    <div className="dash-bars"></div>
                                    <img src={graphic} className="w-100 img-fluid" alt="" />
                                    <ul className="months">
                                        <li>Ene</li>
                                        <li>Feb</li>
                                        <li>Mar</li>
                                        <li>Abr</li>
                                        <li>May</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="stats w-100 d-flex flex-wrap mb-2 border-bottom border-danger">
                        <div className="tipster-main col-4 d-flex flex-wrap justify-content-between">
                            <div className="col-12 col-md-5 d-none d-xl-flex align-items-center justify-content-center">
                                <img src={userLogo} alt="" />
                            </div>
                            <div className="tipster-stats col-12 col-xl-7 d-flex flex-column justify-content-center">
                                <h4 className="mb-0 text-center fw-bold">1766</h4>
                                <p className="mb-0 text-center fw-light" style={{ fontSize: '12px' }}>Followers</p>
                            </div>
                        </div>
                        <div className="col-8 d-flex flex-wrap align-items-center">
                            <div className="col-6 col-sm-3 col-md-6 col-xl-3">
                                <h5 className="mb-0 text-center fw-bold text-danger">24</h5>
                                <p className="mb-0 text-center fw-light" style={{ fontSize: '10px' }}>Ranking</p>
                            </div>
                            <div className="col-6 col-sm-3 col-md-6 col-xl-3">
                                <h5 className="mb-0 text-center fw-bold">1.756</h5>
                                <p className="mb-0 text-center fw-light" style={{ fontSize: '10px' }}>Picks</p>
                            </div>
                            <div className="col-6 col-sm-3 col-md-6 col-xl-3">
                                <h5 className="mb-0 text-center fw-bold">16%</h5>
                                <p className="mb-0 text-center fw-light" style={{ fontSize: '10px' }}>Yield</p>
                            </div>
                            <div className="col-6 col-sm-3 col-md-6 col-xl-3">
                                <h5 className="mb-0 text-center fw-bold">43%</h5>
                                <p className="mb-0 text-center fw-light" style={{ fontSize: '10px' }}>Win rate</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-wrap">
                        <ul className="checks">
                            <li>
                                <img src={noValid} alt="" />
                            </li>
                            <li>
                                <img src={valid} alt="" />
                            </li>
                            <li>
                                <img src={valid} alt="" />
                            </li>
                            <li>
                                <img src={noValid} alt="" />
                            </li>
                            <li>
                                <img src={valid} alt="" />
                            </li>
                            <li>
                                <img src={valid} alt="" />
                            </li>
                            <li>
                                <img src={valid} alt="" />
                            </li>
                            <li>
                                <img src={noValid} alt="" />
                            </li>
                            <li>
                                <img src={noValid} alt="" />
                            </li>
                            <li>
                                <img src={valid} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { TipsterCard };