import ServiceCard from './card/service-card';
import { ServiceList } from 'customTypes';

const ServicesLayout = (services:ServiceList) => {
    return (
        <>
            <div className="row">
                <h1 className="title title__pop">Asi Se hace</h1>
                <h2 className="title fs-1 fw-bold text-center">¿Cómo usar <span>apuestes?</span></h2>
            </div>
            <div className="container service service__container">
                <div className="row justify-content-center">
                    {Object.values(services).map(item => (
                        <ServiceCard key={item.id} {...item} />
                    ))}
                </div>
                <div className="my-5 row justify-content-center">
                    <a href="!#" className="z-2 px-4 px-lg-5 rounded-pill btn btn-lg btn-primary text-white" style={{ width: '275px' }}>¡EMPIEZA AHORA!</a>
                </div>
            </div>
        </>
    );
}

export {ServicesLayout};