import userAvatar from '../../images/feedback/user-avatar.svg';
import patternRight from '../../images/patterns/pattern-right.png';
import {FeedbackCard} from './feedback-card/feedback-card';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";

const FeedbackLayout = () => {
    return(
        <div className="feedback feedback__container pt-5 position-relative">
            <img src={ patternRight } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-400px', right: '-30px' }}/>
            <div className="container feedback__title-section">
                <div className="row">
                    <h1 className="title title__pop">Asi Opinan</h1>
                    <h2 className="title fs-1 fw-bold text-center">Sobre <span>nosotros</span></h2>
                </div>
            </div>
            <div className="row position-relative py-5 bg-primary-light justify-content-center carousel-row">
                <div className="col-lg-10 col-xl-9 col-xxl-6 position-absolute" style={{ top: '-225px' }}>
                    <Swiper
                    className="mySwiper"
                    navigation={true}
                    modules={[Navigation]}
                    centeredSlides={true}
                    loop={true}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 20
                        },
                        // when window width is >= 640px
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 40
                        }
                      }}
                    >
                        <SwiperSlide>
                            <FeedbackCard userAvatar={ userAvatar } />
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeedbackCard userAvatar={ userAvatar } />
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeedbackCard userAvatar={ userAvatar } />
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeedbackCard userAvatar={ userAvatar } />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export {FeedbackLayout};