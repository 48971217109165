import { ServiceCard } from "../components/service-card/ServiceCard";
import { eyeIcon, serviceMarketIcon, serviceClockIcon, serviceIcon, stakeIcon } from "../../../images/tipster";
import { ChannelCard } from "../components/channel-card/ChannelCard";

const srv = [
    {
        name: 'Servicio',
        icon: serviceIcon.default
    },
    {
        name: 'Mercado',
        icon: serviceMarketIcon.default
    },
    {
        name: 'Apuestas',
        icon: eyeIcon.default
    },
    {
        name: 'Horario',
        icon: serviceClockIcon.default
    },
    {
        name: 'Stake Medio',
        icon: stakeIcon.default
    },
]

const Services = () => {
    return (
        <>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="row">
                            {srv.map((el: any, idx: number) => (
                                <div className="col-md-4">
                                    <ServiceCard obj={ el } />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-3 d-none d-lg-block">
                        {[1,2,3,4].map( (el, idx) => (
                            <>
                                <ChannelCard />
                            </>
                        ) )}
                    </div>
                </div>
            </div>
        </>
    );
};

export { Services };