import { useEffect, useRef } from 'react';
import 'toolcool-range-slider';
import { RangeSlider } from 'toolcool-range-slider';
import 'toolcool-range-slider/dist/plugins/tcrs-moving-tooltip.min.js';

const RangeSlide = () => {

  const sliderRef = useRef<RangeSlider>(null);

  useEffect(() => {

      const slider = sliderRef.current;

      const onChange = (evt: Event) => {
          const customEvent = evt as CustomEvent;
          console.log(customEvent.detail.value);
      };

      slider?.addEventListener('change', onChange);

      return () => {
        slider?.removeEventListener('change', onChange);
      };
  }, []);

  return (
    <div className='py-3'>
        <tc-range-slider
            moving-tooltip="true"
            moving-tooltip-distance-to-pointer="20"
            moving-tooltip-width="35"
            moving-tooltip-height="20"
            moving-tooltip-bg="#E73758"
            moving-tooltip-text-color="#fff"
            ref={ sliderRef }
        />
    </div>
  );
}

export { RangeSlide };