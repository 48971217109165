import patternLeft from '../images/patterns/pattern-left.png';
import patternRight from '../images/patterns/pattern-right.png';

const items = [
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 1' },
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 2' },
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 3' },
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 4' },
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 5' },
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 6' },
  { text: "Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.", label: 'Pregunta 7' },
]

const Faqs = () => {
  return (
    <div className="faqs-page position-relative">
        <img src={ patternLeft } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ top: '-100px', left: '-90px' }}/>
        <div className="container faqs faqs__container">
          <div className="w-100 mb-4 row">
              <h1 className="title title__pop mb-3">FAQS</h1>
              <h2 className="title fs-2 fw-normal text-center">Resuelve tus dudas y <span>disfruta al 100%</span> de la web</h2>
          </div>
          <div className="w-100 row my-4">
            <div className="card border-0 accordion accordion-flush z-1" id="accordionFlushExample">
            {Object.values(items).map(({label, text}, i) => (
              <div className="accordion-item">
                <h2 className="accordion-header" id={ "flush-heading" + i }>
                  <button className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target={ "#flush-collapse" + i } aria-expanded="false" aria-controls={ "flush-collapse" + i }>
                    { label }
                  </button>
                </h2>
                <div id={ "flush-collapse" + i } className="accordion-collapse collapse" aria-labelledby={ "flush-heading" + i } data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body" dangerouslySetInnerHTML={{ __html: text }}></div>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
        <img src={ patternRight } alt="" className="pattern d-none d-lg-block img-fluid position-absolute" style={{ bottom: '-250px', right: '-90px' }}/>
    </div>
  );
}

export {Faqs};